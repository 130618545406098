<template>
  <div>
    <div class="headerbox">
    </div>
    <div class="center">
      <div class="titlechange" id="titlechange">
        <div class="titleitem point" :class="{'actitle':titleindex===index}" v-for="item , index in titlelist" :key="item.id" @click="titleclick(index,item.id)">
          {{item.name}}
        </div>
      </div>
      <div class="newlist">
        <!-- 第一页 -->
        <div v-if="currentPage==1">
          <div class="page1box">
            <div class="lbox point" v-if="page1data?.image" @click="todetaile(page1data.id)">
              <img :src="onlineUrl+page1data?.image" alt="">
              <span class="page1title e">{{page1data?.title}}</span>
            </div>
            <div class="rbox">
              <div class="ritem point" v-for="item,index in newlistdata.slice(1,4)" :key="index" @click="todetaile(item.id)">
                <div class="centeritem">
                  <div class="timebox"> {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy") }}</div>
                  <div class="titlebox e">{{item.title}}</div>
                  <div class="content" v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="alllistdata">
            <div class="dataitem point" v-for="item,index in newlistdata.slice(4)" :key="index" @click="todetaile(item.id)">
              <div class="datacenter">
                <div class="datatimebox"> {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy") }}</div>
                <div class="datatitlebox">{{item.title}}</div>
                <div class="datacontent" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="alllistdata" v-else>
          <div class="dataitem point" v-for="item,index in newlistdata " :key="index" @click="todetaile(item.id)">
            <div class="datacenter">
              <div class="datatimebox"> {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy") }}</div>
              <div class="datatitlebox">{{item.title}}</div>
              <div class="datacontent" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagechange">
        <van-pagination class="commonpage" v-model="currentPage" :total-items="total" :items-per-page="per_page"   force-ellipses @change="changepage">
          <template #prev-text>
            <img class="prev-l prev-no-dis" src="../../assets/images/all/pc-productd-r.png" alt="">
            <img class="prev-l prev-dis" src="../../assets/images/all/pc-icon18.png" alt="">
          </template>
          <template #next-text>
            <img class="prev-r prev-no-dis" src="../../assets/images/all/pc-productd-r.png" alt="">
            <img class=" prev-r prev-dis" src="../../assets/images/all/pc-icon18.png" alt="">
          </template>
        </van-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { getnews_categorylApi, getnews_listlApi } from '../../network/api';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      titleindex: 0,
      titlelist: [

      ],
      titleid: null,
      newlistdata: [

      ],
      page1data: {

      },
      currentPage: 1,
      total: 15,
      per_page: 10
    };
  },
  computed: {
    ...mapState(["onlineUrl"]),
  },
  created() {
    this.gettypedata();
  },
  methods: {
    todetaile(v) {
      this.$router.push(
        {
          path: "/NewsDetaile",
          query: {
            id: v
          }
        }
      );
    },
    changepage(v) {
      this.currentPage = v;
      this.getnewlistdata();
      this.backTop();
    },
    backTop() {
      const scrollTop = document.getElementById("titlechange");
      scrollTop.scrollIntoView();
    },
    async gettypedata() {
      const res = await getnews_categorylApi();
      this.titlelist = res.data;
      this.titleid = res.data[0].id;
      this.getnewlistdata();
    },
    titleclick(v, id) {
      this.titleindex = v;
      this.titleid = id;
      this.getnewlistdata();
    },
    async getnewlistdata() {
      let params = {
        category_id: this.titleid,
        page: this.currentPage
      };
      const res = await getnews_listlApi(params);
      // console.log(res, 'newdata');
      this.newlistdata = res.data.data;
      this.total = res.data.total;
      this.per_page = res.data.per_page;
      this.page1data = res.data.data[0];
      // console.log(this.page1data);
    }
  }
}
</script>

<style lang="scss" scoped>
.headerbox {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/all/pc-new-title1.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.6rem;
  .centertext {
    width: 6.97rem;
    height: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    font-family: Paytone One, Paytone One;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    .line {
      width: 3rem;
      height: 0.02rem;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
    .line2 {
      width: 3rem;
      height: 0.02rem;
      background: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.center {
  width: 13rem;
  margin: 0 auto;
  overflow: hidden;
  .titlechange {
    display: flex;
    margin-bottom: 0.6rem;
    .titleitem {
      font-size: 0.2rem;
      border: 0.02rem solid #015f3d;
      padding: 0.15rem 0.23rem;
      border-radius: 0.34rem;
      margin-right: 0.24rem;
      box-shadow: 0 0.04rem 0.08rem 0 #c9dfd7;
    }
    .actitle {
      background: #005e3c;
      color: #fff;
    }
  }
  .page1box {
    display: flex;
    justify-content: space-between;
    .lbox {
      width: 7.25rem;
      height: 4.4rem;
      position: relative;
      margin-top: 0.08rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .page1title {
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: 7.25rem;
        height: 1.25rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        bottom: 0;
        font-size: 0.24rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        padding-left: 0.4rem;
        line-height: 1.25rem;
      }
    }
    .rbox {
      width: 5.4rem;
      .ritem {
        width: 100%;
        height: 1.4rem;
        background: #ecf1eb;
        margin-top: 0.08rem;
        border-radius: 0.06rem;
        overflow: hidden;
        .centeritem {
          width: 5rem;
          margin: 0 auto;
          .timebox {
            margin: 0.15rem 0;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.16rem;
          }
          .titlebox {
            font-size: 0.24rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 0.28rem;
          }
          .content {
            width: 100%;
            height: 0.35rem;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .alllistdata {
    margin-top: 0.5rem;
    .dataitem {
      width: 100%;
      height: 1.4rem;
      background: #f7faf7;
      border-radius: 0.06rem;
      margin-bottom: 0.3rem;
      overflow: hidden;
      .datacenter {
        width: 12.2rem;
        margin: 0 auto;
        .datatimebox {
          width: 100%;
          text-align: right;
          margin-top: 0.15rem;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #878787;
          line-height: 0.19rem;
        }
        .datatitlebox {
          font-size: 0.24rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 0.28rem;
        }
        .datacontent {
          width: 12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 0.19rem;
          font-size: 0.16rem;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .pagechange {
    margin-bottom: 1.5rem;
  }
  .e {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>